<template>
  <v-app-bar id="app-bar" absolute app color="transparent" flat height="75">
    <v-btn class="mr-3" elevation="1" fab small @click="setDrawer(!drawer)">
      <v-icon v-if="value">
        mdi-view-quilt
      </v-icon>

      <v-icon v-else>
        mdi-dots-vertical
      </v-icon>
    </v-btn>

    <v-toolbar-title
      class="hidden-sm-and-down font-weight-light"
      v-text="$route.name"
    />

    <v-spacer />

    <v-select
      v-model="regionId"
      class="pt-4"
      style="width:1px"
      :items="regionSelectList"
      label="Region"
      persistent-hint
    />

    <div class="mx-3" />

    <v-btn class="ml-2" min-width="0" text to="/">
      <v-icon>mdi-view-dashboard</v-icon>
    </v-btn>

    <v-menu
      bottom
      left
      offset-y
      origin="top right"
      transition="scale-transition"
    >
    <!--
      <template v-slot:activator="{ attrs, off }">
        <v-btn class="ml-2" min-width="0" text v-bind="attrs" v-on="on">
          <v-badge color="red" overlap bordered>
            <template v-slot:badge>
              <span>0</span>
            </template>
            <v-icon>mdi-bell</v-icon>
          </v-badge>
        </v-btn>
      </template>
-->
      <v-list :tile="false" nav>
        <div>
          <app-bar-item v-for="(n, i) in notifications" :key="`item-${i}`">
            <v-list-item-title v-text="n" />
          </app-bar-item>
        </div>
      </v-list>
    </v-menu>
<!--
    <v-btn class="ml-2" min-width="0" text to="/pages/user">
      <v-icon>mdi-account</v-icon>
    </v-btn>-->
    <v-btn class="ml-2" min-width="0" text @click="logout">
      <v-icon color >
      mdi-logout
      </v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script>
// Components
import { VHover, VListItem } from "vuetify/lib"

// Utilities
import { mapState, mapMutations, mapGetters } from "vuex"

export default {
  name: "DashboardCoreAppBar",

  components: {
    AppBarItem: {
      render(h) {
        return h(VHover, {
          scopedSlots: {
            default: ({ hover }) => {
              return h(
                VListItem,
                {
                  attrs: this.$attrs,
                  class: {
                    "black--text": !hover,
                    "white--text secondary elevation-12": hover
                  },
                  props: {
                    activeClass: "",
                    dark: hover,
                    link: true,
                    ...this.$attrs
                  }
                },
                this.$slots.default
              )
            }
          }
        })
      }
    }
  },

  props: {
    value: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    notifications: [

    ]
  }),

  computed: {
    ...mapState(["drawer"]),
    ...mapGetters(["regionList"]),
    regionSelectList: {
      get() {
        let regions = this.regionList
        let rlist = []
        regions.forEach(regionData => rlist.push(regionData["region_id"]))
        return rlist
      }
    },
    regionId: {
      get() {
        return this.$store.state.regionId
      },
      set(val) {
        localStorage.regionId = val
        this.$store.commit("setCurrentRegion", val)
        this.$store.dispatch("regionDataUpdater")
      }
    }
  },
  mounted() {
    this.loadRegions()
  },
  methods: {
    async loadRegions() {
      const accessToken = await this.$auth.getAccessToken()
      console.log('accessToken: ',accessToken)
      this.$store.commit('setAccessToken', accessToken)
      this.$store.dispatch('loadRegions')
    },

    ...mapMutations({
      setDrawer: "SET_DRAWER"
    }),

    logout: function() {
      this.$emit("logout")
    }
  }
}
</script>
